import React, {useEffect, useState} from 'react';
import { graphql } from 'gatsby';
import { useI18next } from "../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import {
    layout,
    results,
} from './summer-contest-thank-you-page.module.scss';

import { IPage } from '../models/page.model';
import { IFlavour } from '../models/flavour';
import { getNodes } from '../utils/get-nodes';
import { getFlavourResults}  from "../utils/get-flavour-results";
import useQueryParam from '../hooks/use-query-param';

import SummerContestLayout from "../layouts/summer-contest-layout";
import ContestResults from "../components/organisms/contest-results";

interface ISummerContestThankYouPage {
    readonly data: {
        page: IPage | null;
        flavours: {
            edges: {
                node: IFlavour;
            }[];
        };
    };
}

const SummerContestThankYouPage: React.FC<ISummerContestThankYouPage> = ({ data }) => {
    const { page, flavours } = data;
    const { t } = useI18next();
    const [showResults, setShowResults] = useState(false);
    const queryResults = useQueryParam('results').values[0];
    const resultFlavours = queryResults ? getFlavourResults(getNodes(flavours), queryResults) : [];

    useEffect(() => {
        if (resultFlavours.length) {
            setShowResults(true);
        }
    }, [resultFlavours.length]);

    return (
        <SummerContestLayout className={layout} page={page} headerProps={{
            titleFirstLine: t('summer.contest.thank.you.header.1'),
            titleSecondLine: t('summer.contest.thank.you.header.2'),
            children: t('summer.contest.thank.you.header.3'),
        }}>
            {showResults && (
                <ContestResults
                    titleFirstLine={t('summer.contest.thank.you.results.1')}
                    titleSecondLine={t('summer.contest.thank.you.results.2')}
                    className={results}
                    hasPodium={true}
                    flavourResults={resultFlavours}
                />
            )}
        </SummerContestLayout>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        flavours: allStrapiSummerFlavour(filter: {locale: { eq: $language }}) {
            edges {
                node {
                    name
                    value
                    image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(placeholder: BLURRED, quality: 99, width: 125)
                            }
                        }
                        alternativeText
                    }
                }
            }
        }

        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default SummerContestThankYouPage;
