import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useI18next } from "../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import { container, title, accent, list, withPodium, itemContent, item, itemImage, itemName, itemNameAccent, bar, barFill, barText, barInnerWrapper } from './contest-results.module.scss';
import { IResultFlavour } from "../../models/flavour";

interface IContestResultsProps {
    className?: string;
    flavourResults: IResultFlavour[];
    hasPodium: boolean;
    titleFirstLine?: string;
    titleSecondLine?: string;
}

const ContestResults: React.FC<IContestResultsProps> = ({ className = '', flavourResults, hasPodium = false, titleFirstLine, titleSecondLine }) => {
    const { t } = useI18next();

    return (
        <div className={`${container} ${className}`}>
            {(titleFirstLine || titleSecondLine) && (
                <h2 className={title}>
                    {titleFirstLine && <span className={`${accent} title accent`}>{titleFirstLine}</span>}
                    {titleSecondLine && <span className={'title'}>{titleSecondLine}</span>}
                </h2>
            )}
            <ul className={`${list} ${hasPodium ? withPodium : ''}`}>
                {flavourResults.map((flavour) => {
                    const image = flavour.image && getImage(flavour.image.localFile);
                    return (
                        <li
                            key={`flavour-${flavour.value}`}
                            className={item}
                        >
                            <div className={itemContent}>
                                {image && (
                                    <GatsbyImage
                                        className={itemImage}
                                        alt={flavour.image.alternativeText}
                                        image={image}
                                    />
                                )}
                                <p className={itemName}>
                                    <span className={itemNameAccent}>
                                        {t('contest.results.name')}
                                    </span>
                                    <span>{flavour.name}</span>
                                </p>
                            </div>
                            <div className={bar}>
                                <div className={barInnerWrapper}>
                                    <span className={barText}>
                                        {flavour.result}% {t('contest.results.votes')}
                                    </span>
                                    <div
                                        className={barFill}
                                        style={{ width: `${parseFloat(flavour.result) < 95 ? parseFloat(flavour.result) + 5 : flavour.result}%` }}
                                    />
                                </div>
                            </div>
                        </li>
                    )
                })}
            </ul>
        </div>
    );
};

export default ContestResults;