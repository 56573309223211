// extracted by mini-css-extract-plugin
export var accent = "contest-results-module--accent--7a99a";
export var bar = "contest-results-module--bar--22577";
export var barFill = "contest-results-module--bar-fill--8d690";
export var barInnerWrapper = "contest-results-module--bar-inner-wrapper--030a4";
export var barText = "contest-results-module--bar-text--74c0b";
export var container = "contest-results-module--container--f1e5d";
export var errorBlink = "contest-results-module--error-blink--f461a";
export var item = "contest-results-module--item--4aaa1";
export var itemContent = "contest-results-module--item-content--ced76";
export var itemImage = "contest-results-module--item-image--bfeb7";
export var itemName = "contest-results-module--item-name--5ecd8";
export var itemNameAccent = "contest-results-module--item-name-accent--3f2fd";
export var list = "contest-results-module--list--206c3";
export var title = "contest-results-module--title--5043e";
export var withPodium = "contest-results-module--with-podium--4fa05";